import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { useState } from 'react';
import { Entity } from '@backstage/catalog-model';

type EnvironmentReturnType = {
  hasEnvironmentSet?: boolean;
  loadEnvironmentByDomain: (domain: string) => void;
  environment?: Entity;
};
export function useCatalogInfoLoader(): EnvironmentReturnType {
  const [state, setState] =
    useState<Pick<EnvironmentReturnType, 'environment' | 'hasEnvironmentSet'>>();

  const catalogApi = useApi(catalogApiRef);
  const updateState = (environments: Entity[]) => {
    const hasEnvironmentSet = environments.length > 0;
    const environment = environments.shift();
    setState({ environment, hasEnvironmentSet });
  };
  const loadEnvironmentByDomain = (domain: string) => {
    catalogApi
      .getEntities({
        filter: {
          kind: 'EnvironmentSet',
          'spec.domain': domain,
          'spec.enabled': `${true}`,
          'spec.type': 'kubernetes'
        },
        fields: ['metadata.name']
      })
      .then((response) => updateState(response.items));
  };

  return { ...state, loadEnvironmentByDomain };
}
