import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { FieldValidation } from '@rjsf/utils';

let isRepoNameTooLong = false;

export const GBReviewRepository = ({
  formContext,
  onChange,
}: FieldExtensionComponentProps<string>) => {
  const [domain, setDomain] = React.useState<any>({});

  const catalogApi = useApi(catalogApiRef);
  const componentFullName = Object.values(formContext?.formData?.component ?? {}).filter(Boolean).join('-');
  const nameParts = [
    domain?.metadata?.acronym ?? '',
    formContext?.formData?.system ?? '',
    componentFullName,
  ];
  const repoName = nameParts.filter(part => !!part).join('-');
  const domainStorage = sessionStorage.getItem('Domain') ?? '';

  const getDomainInfo = async () => {
    if (domainStorage && formContext.formData?.system) {
      const getDomain = () => {
        const request = {
          filter: {
            kind: ['Domain'],
            'metadata.name': domainStorage,
          },
        };

        catalogApi.getEntities(request).then(response => {
          if (response.items.length > 0) {
            setDomain(response.items[0]);
          }
        });
      };
      getDomain();
    } else {
      setDomain(undefined);
    }
  };

  useEffect(() => {
    getDomainInfo();
  }, [formContext.formData.system]);

  useEffect(() => {
    if (isRepoNameTooLong) {
      onChange(undefined);
    } else {
      onChange(repoName);
    }
  }, [repoName]);

  isRepoNameTooLong = repoName.length > 63;

  return (
    <FormControl error={isRepoNameTooLong}>
      <TextField
        error={isRepoNameTooLong}
        id="repo-review-name"
        label="Repository Github Name"
        title="Repository Name"
        margin="normal"
        variant="outlined"
        disabled
        value={repoName}
      />
      <FormHelperText>
        {isRepoNameTooLong
          ? 'Repository name is too long (max 63 characters). Repository name is composed of: [vs-acronym]-[system-name]-[component-name]'
          : 'This is a preview of your repository name on github. The name follows the rule: grupoboticario/[vs-acronym]-[system-name]-[component-name]'}
      </FormHelperText>
    </FormControl>
  );
};

export const GBReviewRepositoryFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBReviewRepository,
    name: 'GBReviewRepository',
    validation: (_: any, fieldValidation: FieldValidation) => {
      if (isRepoNameTooLong) {
        fieldValidation.addError(
          `Por favor, reduza o comprimento do nome do seu repositório`,
        );
      }
    },
  }),
);
