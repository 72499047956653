import React from 'react';
import { EntityConsumedApisCard, EntityProvidedApisCard } from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntitySwitch
} from '@backstage/plugin-catalog';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import Grid from '@mui/material/Grid';
import { AutoServiceEntityPage } from '@grupoboticario/plugin-auto-service-frontend';
import { EntityArgoCDContent } from '@grupoboticario/plugin-argo-cd-frontend';
import { OverviewContent } from '../../Contents/Overview';
import { ReadmePage } from '../../Readme';
import { CICDContent } from '../../Contents/CICD';
import { CostsContent } from '../../Contents/Costs';
import { SecurityContent } from '../../Contents/Security';
import {
  isConsumesApisEnable,
  isDependencyOfComponentEnable,
  isDependsOnResourceEnable,
  isProviderApisEnable
} from '../conditions';

const isValidType = (type: string) =>
  [
    'api-gateway',
    'doc',
    'etl',
    'infra',
    'lib',
    'kubernetes',
    'serverless',
    'static-frontend',
    'non-standard',
    'gh-actions',
    'others'
  ].includes(type);

const isAll = (type: string) =>
  [
    'api-gateway',
    'etl',
    'infra',
    'lib',
    'kubernetes',
    'serverless',
    'static-frontend',
    'non-standard',
    'gh-actions',
    'others'
  ].includes(type);

const isWithResource = (type: string) =>
  ['kubernetes', 'serverless', 'static-frontend','others'].includes(type);

const isWithCost = (type: string) =>
  ['kubernetes', 'etl', 'serverless', 'static-frontend', 'others'].includes(type);

type ComponentTypes = {
  path: string;
  title: string;
  componentType?: string;
  content: React.JSX.Element;
  showContent: (type: string, allowed?: boolean) => boolean;
};
const components: ComponentTypes[] = [
  {
    path: '/',
    title: 'Overview',
    showContent: (_: string) => true,
    content: OverviewContent
  },
  {
    path: '/readme',
    title: 'Readme',
    showContent: (_: string) => true,
    content: ReadmePage
  },
  {
    path: '/docs',
    title: 'Docs',
    componentType: 'docs',
    showContent: (type: string) => type !== 'doc',
    content: <EntityTechdocsContent />
  },
  {
    path: '/ci-cd',
    title: 'CI/CD',
    componentType: 'ci_cd',
    showContent: (type: string) => isAll(type) || !isValidType(type),
    content: CICDContent
  },
  {
    path: '/argocd',
    title: 'ArgoCD',
    componentType: 'argo_cd',
    showContent: (type: string) => isWithResource(type) || !isValidType(type),
    content: <EntityArgoCDContent />
  },
  {
    path: '/costs',
    title: 'Costs',
    componentType: 'costs',
    showContent: (type: string) => isWithCost(type) || !isValidType(type),
    content: CostsContent
  },
  {
    path: '/security',
    title: 'Security',
    showContent: (_: string) => true,
    content: SecurityContent
  },
  {
    path: '/auto-service',
    title: 'Auto Service',
    showContent: (___: string, allowed: boolean) => allowed,
    content: AutoServiceEntityPage
  },
  {
    path: '/api',
    title: 'API',
    componentType: 'api',
    showContent: (_: string) => true,
    content: (
      <Grid container spacing={3} alignItems="stretch">
        <EntitySwitch>
          <EntitySwitch.Case if={(e) => Boolean(isProviderApisEnable(e))}>
            <Grid item md={6}>
              <EntityProvidedApisCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={(e) => Boolean(isConsumesApisEnable(e))}>
            <Grid item md={6}>
              <EntityConsumedApisCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
    )
  },
  {
    path: '/dependencies',
    title: 'Dependencies',
    componentType: 'dependencies',
    showContent: (_: string) => true,
    content: (
      <Grid container spacing={3} alignItems="stretch">
        <EntitySwitch>
          <EntitySwitch.Case if={(e) => Boolean(isDependencyOfComponentEnable(e))}>
            <Grid item md={6}>
              <EntityDependsOnComponentsCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={(e) => Boolean(isDependsOnResourceEnable(e))}>
            <Grid item md={6}>
              <EntityDependsOnResourcesCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
    )
  }
] as unknown as ComponentTypes[];

export default components;
