/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';

import { Link } from '@mui/material';

import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import {
  SidebarScrollWrapper,
  Sidebar,
  SidebarPage,
  SidebarDivider,
  SidebarSpace
} from '@backstage/core-components';

import LogoSidebar from '../../components/LogoSidebar';

import {
  Help,
  Category,
  Build,
  Extension,
  Home,
  Layers,
  LibraryBooks,
  AddCircleOutline,
  Apps,
  AddIcCall
} from '@mui/icons-material';

import { useStyles } from './styles';
import { NavLink } from 'react-router-dom';
import { CustomSidebarItem } from '../../components/customSidebar/index';

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const S = useStyles();

  const SidebarLogo = () => {
    return (
      <div className={S.root}>
        <Link component={NavLink} to="/" underline="none" className={S.link}>
          <LogoSidebar />
        </Link>
      </div>
    );
  };

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SearchContextProvider>
          <SidebarSearchModal />
        </SearchContextProvider>
        <SidebarDivider />
        {/* Global nav, not org-specific */}
        <CustomSidebarItem icon={Home} to="home" text="Home" />
        <CustomSidebarItem icon={Category} to="catalog" text="Catalog" />
        <CustomSidebarItem icon={Extension} to="api-docs" text="APIs" />
        <CustomSidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <CustomSidebarItem icon={AddCircleOutline} to="create" text="Create" />
        <CustomSidebarItem icon={Apps} to="system-catalog" text="Systems" />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <CustomSidebarItem
            icon={Layers}
            to="/docs/default/component/stack-tecnologia-gb"
            text="Tech Stack"
          />
          <CustomSidebarItem icon={Build} to="toolbox" text="ToolBox" />
          <CustomSidebarItem icon={AddIcCall} to="on-call" text="On Call" />
        </SidebarScrollWrapper>
        <SidebarSpace />
        <SidebarDivider />
        <CustomSidebarItem icon={Help} to="/docs/default/component/alquimia/faq/" text="Help" />
        <SidebarDivider />
        <SidebarSettings />
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
