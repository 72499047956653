import React from 'react';

import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
  HomePageToolkit
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

import { Grid, useTheme } from '@mui/material';
import CreateComponentIcon from '@mui/icons-material/Create';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GamesIcon from '@mui/icons-material/Games';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import LogoHome from '../../components/LogoHome';

import { useStyles } from './styles';

export const HomePage = () => {
  const S = useStyles();
  const config = useApi(configApiRef);
  const onboardingUrl: string = config.get('urls.onboarding');
  const playgroundUrl: string = config.get('urls.playground');
  const baseUrl: string = config.get('app.baseUrl') || 'https://alquimia.gb.tech';

  const theme = useTheme().palette.mode;

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content className={S.hometheme}>
          <Grid container justifyContent="center" spacing={4}>
            <HomePageCompanyLogo className={S.container} logo={<LogoHome theme={theme} />} />
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: S.searchBar,
                    notchedOutline: S.searchBarOutline
                  }
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={10}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                  <HomePageToolkit
                    tools={[
                      {
                        url: '/create',
                        label: 'Create Component',
                        icon: <CreateComponentIcon />
                      },
                      {
                        url: '/system-catalog/create',
                        label: 'Create System',
                        icon: <PlaylistAddIcon />
                      },
                      {
                        url: '/docs/default/component/alquimia',
                        label: 'Doc',
                        icon: <MenuBookIcon />
                      },
                      {
                        url: onboardingUrl,
                        label: 'Onboarding',
                        icon: <OndemandVideoIcon />
                      },
                      ...(!baseUrl.includes('play')
                        ? [
                            {
                              url: playgroundUrl,
                              label: 'Playground',
                              icon: <GamesIcon />
                            }
                          ]
                        : [])
                    ]}
                  />
                </Grid>
                <Grid item xs={6} className={S.cont}>
                  <HomePageStarredEntities />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
