import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  CustomIcons: {
    marginRight: '5px',
  },
  CardDivider: {
    marginTop: '10px',
  },
  CardContent: {
    padding: '10px 16px 16px 16px',
  },
  ErrorsList: {
    ListStyleType: 'none',
    paddingTop: '10px',
  },
  ErrorListComponent: {
    padding: 0,
  },
  ComponentWrapper: {
    marginBottom: '10px',
    padding: '10px',
  },
  SpanComponent: {
    fontWeight: 'bold',
  },
  ErrorListItem: {
    padding: '2px',
    display: 'flex',
    width: '100%',
  },
  SuccessWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  LinkFile: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
}));
