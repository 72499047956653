import React from 'react';

import {
  CardMedia,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Link,
  ListItemAvatar
} from '@mui/material';

import { OpenInNew } from '@mui/icons-material';

import * as T from './types';

export function ResourcesListItem({
  image,
  title,
  link,
  resourceName
}: Readonly<T.ResourcesListItemProps>) {
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="openInNew">
          <Link href={link} target="_blank" rel="noopener">
            <OpenInNew />
          </Link>
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>
          <CardMedia component="img" image={image} alt={title || 'image'} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} secondary={resourceName} />
    </ListItem>
  );
}
