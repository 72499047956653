import React from 'react';

import { Grid } from '@mui/material';

import { EntitySwitch } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';

import {
  EntityArgoCDOverviewCard,
  isArgocdAvailable
} from '@grupoboticario/plugin-argo-cd-frontend';
import { NewRelicCard, isNewRelicAvailable } from '@grupoboticario/plugin-newrelic-card-frontend';
import { SreCard, isNewRelicAvailableSre } from '@grupoboticario/plugin-sre-card-frontend';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage-community/plugin-sonarqube-react';

import { GitHubActionsCard, isSonarAvailable } from './Cards';
import { EntityWarningContent } from '../EntityWarning';
import { AboutCard } from '../AboutCard';
import {
  EntityLastLighthouseAuditCard,
  isLighthouseAvailable
} from '@backstage-community/plugin-lighthouse';
import { ResourcesList } from '../ResourcesList';
import { isResourcesCardAvailable } from '../ResourcesList/conditions';

const OverviewContent = (
  <Grid container alignItems="stretch" spacing={3}>
    <EntityWarningContent />
    <Grid item md={12}>
      <AboutCard />
    </Grid>

    <Grid item md={12} container spacing={3}>
      <EntitySwitch>
        <EntitySwitch.Case if={(e) => Boolean(isArgocdAvailable(e))}>
          <Grid item md>
            <EntityArgoCDOverviewCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={(e) => Boolean(isResourcesCardAvailable(e))}>
          <Grid item md>
            <ResourcesList />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={(e) => Boolean(isNewRelicAvailableSre(e))}>
        <Grid item md={6}>
          <SreCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={(e) => Boolean(isNewRelicAvailable(e))}>
        <Grid item md={6}>
          <NewRelicCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isLighthouseAvailable}>
        <Grid item md={12}>
          <EntityLastLighthouseAuditCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={(e) => e.kind === 'Component' && !!e.spec?.dependsOn}>
        <Grid item md={12} xs={12}>
          <EntityCatalogGraphCard
            variant="gridItem"
            height={400}
            maxDepth={1}
            relations={['dependsOn']}
          />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case
        if={(e) => isSonarAvailable() === 'true' && Boolean(isSonarQubeAvailable(e))}
      >
        <Grid item md={12}>
          <EntitySonarQubeCard variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <Grid item md={12}>
      {GitHubActionsCard}
    </Grid>
  </Grid>
);

export { OverviewContent };
