import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  hometheme: {
    backgroundColor: theme.palette.background.default
  },
  container: {
    margin: theme.spacing(5, 3)
  },
  searchBar: {
    maxWidth: '70vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[1]
  },
  searchBarOutline: {
    borderStyle: 'none'
  },
  cont: {
    marginBottom: '5px'
  },
  links: {
    textDecoration: 'underline'
  },
  text: {
    margin: '0px'
  }
}));
