import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

type EnvironmentAlertProps = {
  show: boolean;
};
const EnvironmentAlert = ({ show }: EnvironmentAlertProps) => {
  const showAlert = React.useMemo(() => show, [show]);
  return (
    <>
      {showAlert && (
        <Alert severity="error">
          <AlertTitle>O componente não atende aos requisitos necessários</AlertTitle>
          Por favor, acione o time de devops da sua vs/chapter.
        </Alert>
      )}
    </>
  );
};
export default React.memo(EnvironmentAlert);
