import React, { useState, useEffect } from 'react';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import {
  useApi,
  configApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import functionCaller from './functions';
import { FieldProps } from '@rjsf/utils';
import { useEntity } from '@backstage/plugin-catalog-react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { githubAuthApiRef } from '@backstage/core-plugin-api';
import { GithubAuthApi } from '../../../types/GithubAuthApi';

interface Function {
  name: string;
  params: object;
}

export interface TextValue {
  text: string;
  value: any | string;
}

function isTextValue(object: any): object is TextValue {
  return 'value' in object;
}

export const GBList = ({ onChange, schema, required, uiSchema, formContext }: FieldProps) => {
  if (!uiSchema) throw new Error('uiSchema é obrigatório');
  const uiOptions = 'ui:options';
  const func = uiSchema[uiOptions]?.function as Function;
  const valueProperty = (uiSchema[uiOptions]?.key as string) || undefined;
  const textProperty = (uiSchema[uiOptions]?.textProperty as string) || undefined;
  schema.title = schema.title || 'List';
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const githubAuthApi: GithubAuthApi = useApi(githubAuthApiRef);
  const baseUrl: string = config.get('backend.baseUrl') || '';
  const dependsOn = (uiSchema[uiOptions]?.dependsOn as string) || '';
  const [list, setList] = useState<TextValue[]>([{ value: undefined, text: 'Loading...' }]);
  const [__, setValue] = useState(0);
  const [selectedValue, setSelectedValue] = useState<TextValue | null>(null);

  const forceUpdate = () => {
    return () => setValue((value) => value + 1);
  };

  const entity = useEntity().entity;

  const onSelect = (__: any, selectedOption: TextValue | null) => {
    setSelectedValue(selectedOption?.value ? selectedOption : null);
    onChange(selectedOption?.value ?? undefined);
  };

  const getPath = (obj: any, path: string) => {
    const keys = path.split('.');
    let result = obj;
    for (const key of keys) {
      result = result[key];
    }
    return result;
  };

  async function getOptions() {
    const options = await functionCaller(
      baseUrl,
      func.name,
      { entity, identityApi, formData: formContext.formData, catalogApi, githubAuthApi },
      config
    );
    let formattedOptions: TextValue[] = [];
    if (valueProperty && textProperty) {
      formattedOptions = options.map((o: any) => {
        const value = getPath(o, valueProperty);
        const text = getPath(o, textProperty);
        return { value, text };
      });
    } else if (!valueProperty && textProperty) {
      formattedOptions = options.map((o: any) => {
        const text = getPath(o, textProperty);
        const stringValue = JSON.stringify(o);
        return { value: stringValue, text };
      });
    } else if (options.every((opt: any) => typeof opt !== 'string' && isTextValue(opt))) {
      formattedOptions = options;
    } else {
      formattedOptions = options.map((o: any) => {
        return { value: o, text: o };
      });
    }
    setList(formattedOptions);
  }

  if (dependsOn) {
    if (!formContext || !formContext.formData || !formContext.formData[dependsOn]) {
      useEffect(() => {}, [formContext.formData[dependsOn]]);
      forceUpdate();
    } else {
      useEffect(() => {
        getOptions();
      }, [formContext.formData[dependsOn]]);
    }
  } else {
    useEffect(() => {
      getOptions();
    }, []);
  }

  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          value={selectedValue}
          onChange={onSelect}
          data-testid="gbtech-list"
          id={schema.title}
          getOptionLabel={(option) => option.text}
          options={list}
          renderInput={(params) => (
            <TextField
              {...params}
              id="gbtech-entitypicker-input"
              label={schema.title}
              margin="normal"
              helperText={schema.description}
              variant="outlined"
              required={required}
            />
          )}
        ></Autocomplete>
      </FormControl>
    </>
  );
};
