import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  ListWrapper: {
    backgroundColor: theme.palette.background.default,
    maxHeight: 190,
    overflowY: 'auto',
    borderRadius: 6
  },

  MetricsContainer: {
    position: 'relative'
  },
  SelectWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& > label': {
      fontSize: 14
    }
  },
  Select: {
    height: 42
  },
  MetricItem: {
    '& + &': {
      '&::before': {
        content: `${' "" '}`,
        height: 1,
        display: 'block',
        margin: '12px auto',
        backgroundColor: `${theme.palette.divider}`
      }
    }
  }
}));
