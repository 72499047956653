import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2)
  }
}));
