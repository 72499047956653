import React, { ReactNode, useMemo, useState } from 'react';

import { EntityBadgesDialog } from '@backstage-community/plugin-badges';
import { EntityLayout } from '@backstage/plugin-catalog';

import { Badge } from '@mui/icons-material';

export default function EntityLayoutWrapper(props: { children?: ReactNode }) {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Badges',
        Icon: Badge as any,
        onClick: () => setBadgesDialogOpen(true),
      },
    ];
  }, []);

  return (
    <>
      <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
    </>
  );
}
