import React, { useState } from "react";

import { EntitySwitch } from "@backstage/plugin-catalog";
import { EntityRecentGithubActionsRunsCard, isGithubActionsAvailable } from "@backstage-community/plugin-github-actions";
import { errorApiRef, useApi } from "@backstage/core-plugin-api";

const GitHubActionsCardComponent = () => {
  const NOT_FOUND_ERROR = 404;
  const [errorNotFoundWorflow, setErrorApi] = useState<boolean>(false);

  const errorApi = useApi(errorApiRef);
  errorApi.error$().subscribe((e: any) => { 
    const hasntWorkflowRuns = e.error.status === NOT_FOUND_ERROR

    if (hasntWorkflowRuns) {
      setErrorApi(hasntWorkflowRuns) 
    }
  })

  return (
    <>
      {errorNotFoundWorflow &&
        <EntitySwitch>
          <EntitySwitch.Case if={e => Boolean(isGithubActionsAvailable(e))}>
            <EntityRecentGithubActionsRunsCard limit={4} variant="gridItem" />
          </EntitySwitch.Case>
        </EntitySwitch>}
    </>
  )
}


export const GitHubActionsCard = <GitHubActionsCardComponent />