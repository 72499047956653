import { Entity } from '@backstage/catalog-model';

export const isTabDependenciesEnable = (entity: Entity) =>
  isDependencyOfComponentEnable(entity) || isDependsOnResourceEnable(entity);
export const isDependsOnResourceEnable = (entity: Entity) => !!entity.spec?.dependsOn;
export const isDependencyOfComponentEnable = (entity: Entity) => !!entity.spec?.dependencyOf;

export const isTabApisEnable = (entity: Entity) =>
  isProviderApisEnable(entity) || isConsumesApisEnable(entity);
export const isProviderApisEnable = (entity: Entity) => !!entity.spec?.providesApis;
export const isConsumesApisEnable = (entity: Entity) => !!entity.spec?.consumesApis;
