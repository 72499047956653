import React from 'react';

import { EntityLayout } from '@backstage/plugin-catalog';
import { Grid } from '@mui/material';

import { EntityWarningContent } from '../Contents/EntityWarning';
import EntityLayoutWrapper from '../../../components/EntityLayoutWrapper';
import { AboutCard } from '../Contents/AboutCard';
import { InfraDefaultsCard } from './InfraDefaultsCard';

export const InfraDefaultsPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent/>
        <Grid item xs={12} md={12}>
          <AboutCard hrefTechDocs="/docs/default/component/alquimia/configuration/infraDefaults/" />
        </Grid>
        <Grid item xs={12} md={12}>
          <InfraDefaultsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
