import React from 'react';

import { EntityHasSystemsCard, EntityLayout } from '@backstage/plugin-catalog';
import { Grid } from '@mui/material';

import { EntityWarningContent } from '../Contents/EntityWarning';
import { ListEnvironment } from './ListEnvironments';
import { AboutCard } from '../Contents/AboutCard';

export const DomainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <EntityWarningContent/>
        <Grid item md={12}>
          <AboutCard hrefTechDocs="/docs/default/component/alquimia/configuration/domain/" />
        </Grid>
        <Grid item md={6}>
          <ListEnvironment envType="environmentset" />
        </Grid>
        <Grid item md={6}>
          <ListEnvironment envType="environment" />
        </Grid>
        <Grid item md={6} container spacing={3} alignItems="stretch"></Grid>
        <Grid item md={12}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
