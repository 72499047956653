// @ts-nocheck
// @ts-ignore
import React from 'react';
import { Navigate, Route } from 'react-router';

import Link from '@mui/material/Link';

import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  TableColumn
} from '@backstage/core-components';
import {
  discoveryApiRef,
  microsoftAuthApiRef,
  useApi,
  IdentityApi
} from '@backstage/core-plugin-api';
import { ApiExplorerPage, apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  CatalogTable,
  CatalogTableColumnsFunc,
  CatalogTableRow,
  catalogPlugin
} from '@backstage/plugin-catalog';
import { CatalogImportPage, catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { orgPlugin } from '@backstage/plugin-org';
import { ScaffolderFieldExtensions, ScaffolderLayouts } from '@backstage/plugin-scaffolder-react';
import { SearchPage } from '@backstage/plugin-search';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  TechDocsReaderPage,
  techdocsPlugin
} from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import {
  ScaffolderPage,
  scaffolderPlugin,
  EntityPickerFieldExtension,
  OwnerPickerFieldExtension,
  RepoUrlPickerFieldExtension
} from '@backstage/plugin-scaffolder';
import { badgesPlugin } from '@backstage-community/plugin-badges';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { LightBox, ReportIssue, TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  EntityListContextProps,
  EntityRefLink,
  humanizeEntityRef
} from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { EntityGithubActionsContent } from '@backstage-community/plugin-github-actions';

import { ToolboxPage } from '@drodil/backstage-plugin-toolbox';

import { MermaidBackgroundAddon } from '@grupoboticario/plugin-techdocs-configure-frontend';
import {
  SystemCatalogComponent,
  SystemCatalogEditComponent
} from '@grupoboticario/backstage-plugin-system-catalog-frontend';
import { SecurityFrontendPage } from '@grupoboticario/backstage-plugin-maturity-security-frontend';
import { EntityArgoCDContent } from '@grupoboticario/plugin-argo-cd-frontend';

import { apis } from './apis';
import { Root } from './pages/Root';
import { checkCookie } from './utils/auth';
import { GBComponentNameFieldExtension } from './components/scaffolder/fields/GBComponentName';
import { GBEnvironmentsFieldExtension } from './components/scaffolder/fields/GBEnvironments';
import { GBReviewRepositoryFieldExtension } from './components/scaffolder/fields/GBReviewRepository';
import { GBTechCatalogInfoLoaderFieldExtension } from './components/scaffolder/fields/GBTechCatalogInfoLoader';
import { GBTechEntityPickerFieldExtension } from './components/scaffolder/fields/GBTechEntityPicker';
import { GBTechEntityTagPickerFieldExtension } from './components/scaffolder/fields/GBTechEntityTagPicker';
import { GBTechGetDomainFieldExtension } from './components/scaffolder/fields/GBTechGetDomains';
import { GBTechGetGithubTokenFieldExtension } from './components/scaffolder/fields/GBTechGetGithubToken';
import { GBTechGetGithubUserInfoFieldExtension } from './components/scaffolder/fields/GBTechGetGithubUserInfo';
import {
  GBTechListFieldExtension,
  GBTechListAutoServiceExtension
} from './components/scaffolder/fields/GBTechList';
import { GBTechProxyURLsFieldExtension } from './components/scaffolder/fields/GBTechProxyURLs';
import { GBTechProxyBasePathFieldExtension } from './components/scaffolder/fields/GBTechProxyBasePath';
import { GBTechRepositoryPickerFieldExtension } from './components/scaffolder/fields/GBTechRepositoryPicker';
import { GBTechRestrictedNamesFieldExtension } from './components/scaffolder/fields/GBTechRestrictedNames';
import { GbTechDynamoDBTableSchemaFieldExtension } from './components/scaffolder/fields/GbTechDynamoDBTableSchema';
import { GBRadioGroupFieldExtension } from './components/scaffolder/fields/GBRadioGroup';
import { GBReviewTerraformFieldExtension } from './components/scaffolder/fields/GBReviewTerraform';
import { GBTechVariablesFieldExtension } from './components/scaffolder/fields/GBTechVariables';
import { AwsArnFieldExtension } from './components/scaffolder/fields/AwsArn';
import CustomSearchPage from './pages/CustomSearch';
import { EntityPage } from './pages/Entity';
import { HomePage } from './pages/Home';
import { OnCall } from './pages/OnCall';
import { CostsSummaryLayout } from './components/scaffolder/layouts/CostsSummaryLayout/CostsSummaryLayout';
import { SecretsLayout } from './components/scaffolder/layouts/SecretsLayout/SecretsLayout';
import { ReviewStepComponent as ReviewStep } from './components/scaffolder/review/ReviewStep/ReviewStep';
import { themeConfig } from './theme/index';
import transformErrors from './utils/transformErrors/transformErros';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/material';

const azureB2BProvider = {
  id: 'azure-auth-provider',
  title: 'Grupo Boticário',
  message: 'Login de colaboradores GbTech',
  apiRef: microsoftAuthApiRef
};

const useRootMainStyles = makeStyles((theme) => ({
  root: {
    '& > main': {
      height: 'calc(100vh - 52px)'
    }
  },
  alert:{
    backgroundColor: `color-mix(in srgb, ${theme.palette.warning.dark} 30%, ${theme.palette.background.default} 70%)`,
    fontWeight: 'bold',
  }
}));

const app = createApp({
  apis,
  plugins: [badgesPlugin],
  components: {
    SignInPage: (props) => {
      const discoveryApi = useApi(discoveryApiRef);
      const S = useRootMainStyles();
      return (
        <div className={S.root}>
          <Alert className={S.alert} variant="outlined" severity="warning">
          É necessário estar logado na VPN para acessar a plataforma
          </Alert>
          <SignInPage
            {...props}
            provider={azureB2BProvider}
            title="Select a sign-in method"
            align="center"
            onSignInSuccess={async (result: IdentityApi) => {
              await checkCookie(result, discoveryApi, props);
            }}
          />
        </div>
      );
    }
  },
  ...themeConfig,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex
    });
  }
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

function formatContent(entity: Entity): string {
  return (
    entity.metadata?.title ||
    humanizeEntityRef(entity, {
      defaultKind: 'Component'
    })
  );
}

const columnsFunc: CatalogTableColumnsFunc = (entityListContext: EntityListContextProps) => {
  const columns: TableColumn<CatalogTableRow>[] = [];

  columns.push({
    title: 'name',
    field: 'entity.metadata.name',
    highlight: true,
    width: 'auto',
    customSort({ entity: entity1 }, { entity: entity2 }) {
      // TODO: We could implement this more efficiently by comparing field by field.
      // This has similar issues as above.
      return formatContent(entity1).localeCompare(formatContent(entity2));
    },
    render: ({ entity }) => (
      <EntityRefLink entityRef={entity} defaultKind="Component">
        {entity.metadata.name}
      </EntityRefLink>
    )
  });

  if (entityListContext.entities.some((e) => e.spec?.system)) {
    columns.push(CatalogTable.columns.createSystemColumn());
  }
  if (entityListContext.entities.some((e) => e.spec?.domain)) {
    columns.push({
      title: 'domain',
      field: 'entity.spec.domain',
      width: 'auto'
    });
  }

  if (
    entityListContext.entities.some((e) => {
      return e.metadata.annotations ? e.metadata.annotations['github.com/repo-url'] : undefined;
    })
  ) {
    columns.push({
      title: 'repo',
      field: 'entity.spec.repository.name',
      width: 'auto',
      customSort({ entity: entity1 }, { entity: entity2 }) {
        const repo1 = entity1.metadata.annotations
          ? entity1.metadata.annotations['github.com/full-name']
          : '';
        const repo2 = entity2.metadata.annotations
          ? entity2.metadata.annotations['github.com/full-name']
          : '';
        return repo1.localeCompare(repo2);
      },
      render: ({ entity }) => {
        const link = entity.metadata.annotations
          ? entity.metadata.annotations['github.com/repo-url']
          : '';
        const fullName = entity.metadata.annotations
          ? entity.metadata.annotations['github.com/full-name']
          : '';
        return (
          <Link href={link} target="_blank">
            {fullName}
          </Link>
        );
      }
    });
  }

  if (
    entityListContext.entities.some((e) => {
      return (e.spec?.author as any)?.login;
    })
  ) {
    columns.push({
      title: 'author',
      field: 'entity.spec.author.login',
      width: 'auto'
    });
  }

  if (entityListContext.entities.some((e) => e.spec?.owner)) {
    columns.push(CatalogTable.columns.createOwnerColumn());
  }
  if (entityListContext.entities.some((e) => e.spec?.type)) {
    columns.push(CatalogTable.columns.createSpecTypeColumn());
  }
  if (entityListContext.entities.some((e) => e.spec?.lifecycle)) {
    columns.push(CatalogTable.columns.createSpecLifecycleColumn());
  }

  columns.forEach((c) => (c.width = 'auto'));

  if (entityListContext.entities.some((e) => e.metadata.tags)) {
    columns.push(CatalogTable.columns.createTagsColumn());
    columns[columns.length - 1].width = '150px';
  }

  return columns;
};

const components = {
  ReviewStepComponent: ReviewStep
};

const buildTemplateGroups = () => {
  const templateGroups = [
    { title: 'API Gateway', value: 'api-gateway' },
    { title: 'Catalog', value: 'catalog' },
    { title: 'Deprecated', value: 'deprecated' },
    { title: 'Documentation', value: 'documentation' },
    { title: 'ETL', value: 'etl' },
    { title: 'Infra', value: 'infra' },
    { title: 'Library', value: 'lib' },
    { title: 'Kubernetes', value: 'kubernetes' },
    { title: 'Mobile', value: 'mobile' },
    { title: 'Monitoring', value: 'monitoring' },
    { title: 'Serverless', value: 'serverless' },
    { title: 'Static FrontEnd', value: 'static-frontend' },
    { title: 'Non Standard', value: 'non-standard' }
  ];

  return templateGroups.map((group) => ({
    title: group.title,
    filter: (entity: any) => entity?.spec?.type === group.value
  }));
};

const routes = (
  <FlatRoutes>
    {/* Redirects to avoid 404 navigation on documentation */}
    <Route
      path="/reuse-radar"
      element={
        <TechRadarPage
          width={1200}
          height={500}
          title="Reuso de Soluções"
          subtitle="Confira abaixo os softwares desenvolvidos pelo Grupo Boticário e que devem ser reutilizados por todos de acordo com sua definição"
          pageTitle="Para mais detalhes, sobre as camadas, obrigações e modelo de trabalho, procure a documentação da stack de tecnologia no menu esquerdo"
        />
      }
    />
    <Route
      path="/docs/default/component/alquimia/backstage/docs"
      element={<Navigate to="/docs/default/component/alquimia/techdocs/techdocs" />}
    />
    <Route
      path="/docs/default/component/local/backstage/catalog"
      element={<Navigate to="/docs/default/component/alquimia/catalog/catalog" />}
    />
    <Route
      path="/docs/default/component/local/backstage/component-config"
      element={<Navigate to="/docs/default/component/local/catalog/component-config/" />}
    />
    <Route
      path="/docs/default/component/alquimia/backstage/organization"
      element={<Navigate to="/docs/default/component/alquimia/catalog/organization/" />}
    />
    <Route
      path="/docs/default/component/local/backstage/catalog-customization/"
      element={<Navigate to="/docs/default/component/local/alquimia/catalog-customization/" />}
    />
    {/* End Redirects */}
    <Route path="/" element={<Navigate to="home" />} />
    <Route path="/home" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage pagination columns={columnsFunc} />} />
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      <GBTechListAutoServiceExtension />
      {EntityPage}
    </Route>
    <Route path="/github-actions" element={<EntityGithubActionsContent />} />
    <Route path="/security-frontend" element={<SecurityFrontendPage />} />
    <Route path="/argocd-entity-content" element={<EntityArgoCDContent />} />
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome initialFilter="all" />
    </Route>
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <Mermaid config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }} />
        <MermaidBackgroundAddon />
        <ReportIssue />
        <TextSize />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          components={components}
          groups={buildTemplateGroups()}
          formProps={{ transformErrors, showErrorList: false }}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <GBRadioGroupFieldExtension />
        <GBEnvironmentsFieldExtension />
        <RepoUrlPickerFieldExtension />
        <OwnerPickerFieldExtension />
        <EntityPickerFieldExtension />
        <GBTechEntityPickerFieldExtension />
        <GBTechGetGithubTokenFieldExtension />
        <GBTechGetGithubUserInfoFieldExtension />
        <GBTechRepositoryPickerFieldExtension />
        <GBTechCatalogInfoLoaderFieldExtension />
        <GBTechEntityTagPickerFieldExtension />
        <GBTechGetDomainFieldExtension />
        <GBComponentNameFieldExtension />
        <GbTechDynamoDBTableSchemaFieldExtension />
        <GBTechRestrictedNamesFieldExtension />
        <GBTechListFieldExtension />
        <GBReviewRepositoryFieldExtension />
        <GBReviewTerraformFieldExtension />
        <GBTechProxyBasePathFieldExtension />
        <GBTechProxyURLsFieldExtension />
        <GBTechVariablesFieldExtension />
        <AwsArnFieldExtension />
      </ScaffolderFieldExtensions>
      <ScaffolderLayouts>
        <CostsSummaryLayout />
        <SecretsLayout />
      </ScaffolderLayouts>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/catalog-import" element={<CatalogImportPage />} />
    <Route path="/search" element={<SearchPage />}>
      <CustomSearchPage />
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/system-catalog" element={<SystemCatalogComponent />} />
    <Route path="/system-catalog/:action" element={<SystemCatalogEditComponent />} />
    <Route path="/system-catalog/:action/:key" element={<SystemCatalogEditComponent />} />
    <Route path="/toolbox" element={<ToolboxPage />} />
    <Route path="/on-call" element={<OnCall />} />
  </FlatRoutes>
);



const App = () => {

  return (
    <AppProvider>
      <AlertDisplay />
      <OAuthRequestDialog />
      <AppRouter>
        <Root>
          <div>{routes}</div>
        </Root>
      </AppRouter>
    </AppProvider>
  );
};

export default App;
