import React, { useCallback } from 'react';

import { Button } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import GitHubIcon from '@mui/icons-material/GitHub';
import DescriptionIcon from '@mui/icons-material/Description';

import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { alertApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';

import { getAnnotationsValue } from '../../../../utils';
import { RenderTags } from '../RenderTags';
import { RenderLink } from '../RenderLink';
import { RenderStatus } from '../RenderStatus';
import { RenderModal } from '../RenderModal';
import { useStyles } from './styles';
import * as T from './types';
import { EndpointsCard } from '../EndpointsCard';
import { ChecksCard } from '../ChecksCard';

export const AboutCard = ({ hrefTechDocs, hrefEdit }: T.Props) => {
  const S = useStyles();
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const errorApi = useApi(errorApiRef);
  const entityNamespace = entity.metadata.namespace;
  const viewUrl = getAnnotationsValue(entity, 'backstage.io/view-url');
  const techDocsRef = getAnnotationsValue(entity, 'backstage.io/techdocs-ref');
  const techDocsSystem =
    techDocsRef && String(techDocsRef).includes('.gb.tech/docs')
      ? '/docs' + techDocsRef.split('/docs').pop()
      : '';
  const techDocs =
    techDocsRef && !String(techDocsRef).includes('.gb.tech')
      ? `/docs/${entityNamespace}/component/${entity.metadata.name}`
      : '';

  const systemLocation = entity.metadata.annotations?.['backstage.io/managed-by-location']?.startsWith('entities-api') ? entity.metadata.annotations?.['backstage.io/managed-by-location'].split('/')[4] : ''
  const editSystem = systemLocation ? `/system-catalog/edit/${systemLocation}` : ''
  const editComponent = entity.metadata.annotations?.['backstage.io/edit-url']

  const refreshEntity = useCallback(async () => {
    try {
      await catalogApi.refreshEntity(stringifyEntityRef(entity));
      alertApi.post({
        message: 'Refresh scheduled',
        severity: 'info',
        display: 'transient',
      });
    } catch (e) {
      errorApi.post(e);
    }
  }, [catalogApi, alertApi, errorApi, entity]);

  const removeNamespaceFromValue = (value: string) => {
    return value.replace(`${entityNamespace}/`, '');
  }

  return (
    <div className={S.contAbout}>
      <div>
        <h2 className={S.title}>About</h2>
        <div className={S.propertiesDetails}>
          <div className={S.propertiesDetailsLine}>
            {entity.metadata.description && (
              <div className={S.contDetails}>
                <p className={S.titleProperty}>DESCRIPTION</p>
                <p className={S.resultProperty}>
                  {entity.metadata.description}
                </p>
              </div>
            )}
            {entity.spec?.type && (
              <div className={S.contDetails}>
                <p className={S.titleProperty}>TYPE</p>
                <p className={S.resultProperty}>{`${entity.spec.type}`}</p>
              </div>
            )}
            {entity.kind === 'Component' && (
              <div className={S.contDetails}>
                <p className={S.titleProperty}> CATALOG INTEGRITY</p>
                <RenderModal titleLink="show integrity">
                  <ChecksCard />
                </RenderModal>
              </div>
            )}
            {entity.kind === 'Component' && entity.metadata.links && (
              <div className={S.contDetails}>
                <p className={S.titleProperty}> ENDPOINTS </p>
                <RenderModal titleLink="show URLs">
                  <EndpointsCard {...entity} />
                </RenderModal>
              </div>
            )}
            {entity.spec?.lifecycle && (
              <RenderStatus
                resultProperty={`${entity.spec.lifecycle}`}
                title={'LIFECYCLE'}
              />
            )}
            {entity.spec?.argocdUrl && (
              <div className={S.itemDetails}>
                <p className={S.titleProperty}>ARGOCD URL</p>
                <p className={S.resultProperty}>
                  <a
                    href={`https://${entity.spec.argocdUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={S.link}
                  >
                    {`${entity.spec.argocdUrl}`}
                  </a>
                </p>
              </div>
            )}
            {entity.spec?.devOpsTeam && (
              <div className={S.itemDetails}>
                <p className={S.titleProperty}>DEVOPS TEAM</p>
                <p className={S.resultProperty}>
                  <a
                    href={`/catalog/${entityNamespace}/group/${entity.spec.devOpsTeam}`}
                    target="_blank"
                    className={S.link}
                  >
                    {`${entity.spec.devOpsTeam}`}
                  </a>
                </p>
              </div>
            )}
          </div>
          <div className={S.propertiesDetailsLine}>
            <RenderTags title={'TAGS'} />
            {entity.spec?.owner && (
              <RenderLink
                resultProperty={`${entity.spec.owner}`}
                title={'OWNER'}
                href={`/catalog/${entityNamespace}/group/${removeNamespaceFromValue(String(entity.spec.owner))}`}
              />
            )}
            {entity.spec?.domain && (
              <RenderLink
                resultProperty={`${entity.spec.domain}`}
                title={'DOMAIN'}
                href={`/catalog/default/domain/${entity.spec.domain}`}
              />
            )}
            {entity.spec?.system && (
              <RenderLink
                resultProperty={`${entity.spec.system}`}
                title={'SYSTEM'}
                href={`/catalog/${entityNamespace}/system/${removeNamespaceFromValue(String(entity.spec.system))}`}
              />
            )}
            <RenderTags title={'RUNNERS'} />
            {'enabled' in (entity.spec || {}) && (
              <RenderStatus
                resultProperty={`${entity.spec?.enabled ? 'habilitado' : 'desabilitado'}`}
                title={entity.kind.toUpperCase()}
              />
            )}
          </div>
        </div>
        {hrefTechDocs || viewUrl || techDocs || techDocsSystem ? (
          <div className={S.contActions}>
            <div>
              <p className={S.titleProperty}>ACTIONS</p>
            </div>
            {viewUrl && (
              <Button
                className={S.buttonAction}
                variant="contained"
                color="primary"
                component="a"
                href={String(viewUrl)}
                target="_blank"
              >
                <GitHubIcon className={S.iconsButton} />
                View Source
              </Button>
            )}
            {(hrefTechDocs || techDocs) && (
              <Button
                className={S.buttonAction}
                variant="contained"
                color="primary"
                component="a"
                href={hrefTechDocs || techDocs}
                target="_blank"
              >
                <DescriptionIcon className={S.iconsButton} />
                View TechDocs
              </Button>
            )}
            {techDocsSystem ? (
              <Button
                className={S.buttonAction}
                variant="contained"
                color="primary"
                component="a"
                href={techDocsSystem}
                target="_blank"
              >
                <DescriptionIcon className={S.iconsButton} />
                View System TechDocs
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
      <div>
        <IconButton className={S.iconsButton} onClick={refreshEntity}>
          <CachedIcon />
        </IconButton>
        <IconButton
          className={S.iconsButton}
          component="a"
          href={hrefEdit || editSystem || editComponent}
          target="_blank"
        >
          <EditIcon />
        </IconButton>
      </div>
    </div>
  );
};
