import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import * as T from './types';
import components from './ComponentLayout/components';
import { EntityLayout } from '@backstage/plugin-catalog';
import { usePermission } from '@backstage/plugin-permission-react';
import permission from './ComponentLayout/permission';
import { isArgocdAvailable } from '@grupoboticario/plugin-argo-cd-frontend';
import { Entity } from '@backstage/catalog-model';
import { isTechDocsAvailable } from '@backstage/plugin-techdocs';
import { isGithubActionsAvailable } from '@backstage-community/plugin-github-actions';
import { isCostEnable } from '../Contents/Costs/conditions';
import { isTabApisEnable, isTabDependenciesEnable } from './conditions';

export const ComponentPage = () => {
  const { entity } = useEntity();
  const type = (entity?.spec?.type as T.EntityType) || 'others';
  const conditions: Record<string, Function> = {
    argo_cd: isArgocdAvailable,
    docs: isTechDocsAvailable,
    ci_cd: isGithubActionsAvailable,
    costs: isCostEnable,
    dependencies: isTabDependenciesEnable,
    api: isTabApisEnable
  };

  const inputPermission = {
    permission: permission,
    resourceRef: `component:${entity.metadata.namespace}/${entity.metadata.name}`
  };
  const { allowed } = usePermission(inputPermission);

  return (
    <EntityLayout>
      {components.map(({ path, title, content: Component, componentType, showContent }) => {
        const customValidation = (entity: Entity) => {
          const showComponentByType = showContent(type, allowed);

          if (componentType && conditions.hasOwnProperty(componentType)) {
            return conditions[componentType](entity) && showComponentByType;
          }

          return showComponentByType;
        };

        return (
          <EntityLayout.Route
            path={path}
            title={title}
            key={title}
            if={(entity: Entity) => customValidation(entity)}
          >
            {Component}
          </EntityLayout.Route>
        );
      })}
    </EntityLayout>
  );
};
