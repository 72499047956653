import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';
import { Card, CardContent, FormControl, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { BackendURLs } from './types';

export const GBTechProxyURLs = ({
  onChange,
  schema: { title = 'Backend Addresses', description = 'Set up all environments proxies URLs' },
  required,
  rawErrors,
  formData,
  formContext
}: FieldExtensionComponentProps<BackendURLs>) => {
  const [backendURLs, setBackendURLs] = React.useState<BackendURLs>({});
  useEffect(() => {
    if (Object.keys(formData ?? {}).length > 0) {
      setBackendURLs(formData ?? {});
    }
  }, [backendURLs]);

  return (
    <FormControl data-testid="backend-address-control" fullWidth>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="caption">{description}</Typography>
      {formContext.formData.environmentSet.spec.environments.map((env: any) => (
        <>
          <Card elevation={12} square={false}>
            <CardContent>
              <TextField
                role="form"
                data-testid={`backend-address-${env.stage}`}
                key={env.stage}
                label={`Base URL for ${env.stage.toUpperCase()}`}
                value={backendURLs[env.stage] ?? ''}
                onChange={(event) => {
                  const value = backendURLs;
                  value[env.stage] = event.target.value;
                  setBackendURLs(value);
                  onChange(value);
                }}
                fullWidth
                required={required}
                error={!!rawErrors && rawErrors.length > 0}
                helperText={rawErrors?.join(', ')}
              />
              <Typography variant="caption">{`Example: https://mybackend.${env.stage}.grupoboticario.digital/api/v1`}</Typography>
            </CardContent>
          </Card>
          <br />
        </>
      ))}
    </FormControl>
  );
};

export const GBTechProxyURLsFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechProxyURLs,
    name: 'GBTechProxyURLs'
  })
);
