import { ReviewStepProps } from '@backstage/plugin-scaffolder-react';
import { ReviewState } from '@backstage/plugin-scaffolder-react/alpha';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { ComponentType } from 'react';
import CostsSummary from '../../CostsSummary';
import { useStyles } from './styles';

export const ReviewStepComponent: ComponentType<ReviewStepProps> = ({
  formData,
  steps,
  handleBack,
  handleCreate,
}) => {
  const S = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [isClicked, setIsClicked] = React.useState(false);
  const domain = sessionStorage.getItem('Domain') ?? formData?.domain;
  formData.domain = domain;
  const schemas = steps.map((s) => {
    return { ...s, title: 'satisfy title' };
  });

  const handleClick = () => {
    setIsClicked(true);
    handleCreate();
    setTimeout(() => {
      setIsClicked(false);
    }, 2000);
  };

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item xs={formData?.services ? 8 : 12} className={S.ReviewContainer}>
          <ReviewState formState={formData} schemas={schemas} />
        </Grid>
        <Grid item xs={4} hidden={!formData?.services}>
          <CostsSummary formData={formData} />
          <FormControlLabel
            control={
              <Checkbox
                name="acceptCosts"
                color="primary"
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            label={<Typography>I agree that the costs will be allocated to my product</Typography>}
          />
        </Grid>
      </Grid>
      <div className={S.Footer}>
        <Button onClick={handleBack} className={S.BackButton}>
          Back
        </Button>
        <Tooltip
          title={`${
            !checked
              ? 'Para prosseguir, concorde com os termos de custo marcando a checkbox de custos estimados'
              : ''
          }`}
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              disabled={(!checked && formData?.services !== undefined) || isClicked}
            >
              Create
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  );
};
