import React, { useEffect } from 'react';

import { InfoCard } from '@backstage/core-components';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import type { Entity } from '@backstage/catalog-model';

import List from '@mui/material/List';

import { ResourcesListItem } from './ResourcesListItem';

import { useStyles } from './styles';

export function ResourcesList() {
  const S = useStyles();

  const catalogApi = useApi(catalogApiRef);
  const [fullResourceArray, setFullResourceArray] = React.useState<Entity[]>([]);
  const { entity } = useEntity();

  const getResourceDependencyArray = () => {
    const resourceArray = entity.relations?.filter(
      (relation) => relation.type === 'dependsOn' && (relation as any).target.kind === 'resource'
    );

    return resourceArray;
  };

  const getFullResourceEntity = async () => {
    const dependencyArray = getResourceDependencyArray();

    if (!dependencyArray) return;

    let resourceArray = await Promise.all(
      dependencyArray.map(async (resource) => {
        const request = {
          filter: {
            kind: ['Resource'],
            'metadata.name': (resource as any).target.name
          }
        };

        const resourceEntity = await catalogApi.getEntities(request);
        return resourceEntity.items[0];
      })
    );

    resourceArray = resourceArray.filter((resource) => resource);

    setFullResourceArray(resourceArray);
  };

  useEffect(() => {
    getFullResourceEntity();
  }, []);

  return (
    <InfoCard
      title="AWS Resources"
      subheader="Access AWS resources"
      deepLink={{
        link: 'https://docs.aws.amazon.com/',
        title: 'AWS Documentation'
      }}
    >
      <div className={S.ListWrapper}>
        {fullResourceArray.map((resource) => {
          const cardData = resource.spec?.cardData as any;

          const redirectUrl = cardData?.redirectUrl
            .replaceAll('TEMP_ACCOUNT_ID', (resource.spec?.aws as any)?.awsAccount)
            .replaceAll('TEMP_REGION', (resource.spec?.aws as any)?.awsRegion)
            .replaceAll('TEMP_RESOURCE_NAME', resource.metadata.displayName);

          return (
            <List dense key={resource.metadata.displayName as string}>
              <ResourcesListItem
                image={cardData?.imagePath}
                title={cardData?.label}
                link={redirectUrl}
                resourceName={resource.metadata.displayName as string}
              />
            </List>
          );
        })}
      </div>
    </InfoCard>
  );
}
